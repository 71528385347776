<template>
  <div class="gclDetail_page">
   <div class="container">
       <div class="title">{{detail.name}}</div>
       <div class='demo' v-if="goodsVideo!=null&&goodsVideo!=''">
        <video-player class="video-player vjs-custom-skin"
              ref="videoPlayer"
              :playsinline="true"
              :options="playerOptions">
        </video-player>
      </div>
       <div class="content">
           <div class="item">
               <div class="imgs">
                   <!-- <img :src="detail.image1" alt=""> -->
                   
                    <img v-image-preview :src="detail.image1"/> 
               </div>
               <div class="name">上盖照</div>
           </div>
           <div class="item">
               <div class="imgs">
                   <img v-image-preview :src="detail.image2" alt="">
               </div>
               <div class="name">正脸照</div>
           </div>
           <div class="item">
               <div class="imgs">
                   <img v-image-preview :src="detail.image3" alt="">
               </div>
               <div class="name">侧面照</div>
           </div>
           <div class="item">
               <div class="imgs">
                   <img v-image-preview :src="detail.image4" alt="">
               </div>
               <div class="name">开牙照</div>
           </div>
       </div>
       <div class="section">
           <div class="section_title">虫主</div>
           <div class="text">{{detail.user.name}}</div>
       </div>
       <div class="section">
           <div class="section_title">战绩</div>
           <div class="text">{{detail.record}}</div>
       </div>
        <div class="section">
           <div class="section_title">介绍</div>
           <div class="text">{{detail.content}}</div>
       </div>
   </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
        id:0,
        goodsVideo: '',
        detail: {},
        playerOptions: {
            //播放速度
            playbackRates: [0.5, 1.0, 1.5, 2.0], 
            //如果true,浏览器准备好时开始回放。
            autoplay: false, 
            // 默认情况下将会消除任何音频。
            muted: false, 
            // 导致视频一结束就重新开始。
            loop: false, 
            // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
            preload: 'auto', 
            language: 'zh-CN',
              // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
            aspectRatio: '16:9',
              // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
            fluid: true,
            sources: [{
                //类型
                type: "video/mp4",
                //url地址
                src: '' 
            }],
            //你的封面地址
            poster: '', 
              //允许覆盖Video.js无法播放媒体源时显示的默认信息。
            notSupportedMessage: '此视频暂无法播放，请稍后再试',
            controlBar: {
                timeDivider: true,
                durationDisplay: true,
                remainingTimeDisplay: false,
                //全屏按钮
                fullscreenToggle: true  
            }
        },
    };
  },
  methods: {
   getData() {
       this.$http.GclList(`id=${''}&page=1`).then(res => {
           console.log(res)
           console.log('视频')
           console.log(this.common.setImgLocation(JSON.parse(res.data.user_bug_ach[0].videos)))
           this.detail = res.data.user_bug_ach[0];
           this.goodsVideo = res.data.user_bug_ach[0].videos;
           this.playerOptions.sources[0].src = this.common.setImgLocation(JSON.parse(res.data.user_bug_ach[0].videos))[0];
           console.log(this.playerOptions.sources[0].src)
       })
   }
  },
  mounted() {
    this.id = this.$route.params.id;
    console.log(this.id,'---id')
      this.getData();
  },
  created () {
    setTimeout(() => {
      document.title = this.detail.name + '---中蟋网交易市场---专业蟋蟀蛐蛐活体及鸣虫用品用具交易交流平台！'
    }, 1000)
 },
};
</script>
<style lang="scss">
.gclDetail_page {
  .container {
      margin: 0 auto;
      margin-top: 50px;
 width: 100%;
//  background-color: skyblue;
  }
  .title {
      text-align: center;
      font-size: 24px;
      font-weight: 800;
  }
  .content {
      width: 100%;
      margin-top: 30px;
    //   background-color: pink;
      padding: 0 20px;
      display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
  }
  .item {
      width: 400px;
      height: 430px;
    //   background-color: orange;
      overflow: hidden;
      border-radius: 5px;
      border: 1px solid #c0c0c0;
      margin-bottom: 20px;
      .imgs{
          margin: 20px auto;
          width: 360px;
          height: 360px;
        //   background-color: green;
          img {
              width: 100%;
              height: 100%;
              border-radius: 5px;
          }
      }
      .name{
          text-align: center;
          font-size: 20px;
          color: rgb(107, 107, 107);
      }
  }
}
.section{
    padding: 20px;
    .section_title {
        font-size: 18px;
        font-weight: 800;
        padding-bottom: 10px;
    }
    .text {
        font-size: 16px;
    }
}
.demo{
    width: 800px;
    // height: 338px;
    text-align: center;
    line-height: 100px;
    border: 1px solid transparent;
    border-radius: 4px;
    overflow: hidden;
    background: #fff;
    position: relative;
    box-shadow: 0 1px 1px rgba(0, 0, 0, .2);
    margin: auto;
  }
 
  .demo:hover{
    display: block;
  }
</style>